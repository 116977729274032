import React from "react"
import ProfileRouteAnonymous from "../../../components/auth/ProfileRoute/Anonymous"
import ProposalList from "../../../components/Proposal/ProposalList"

const ProposalsAnonymousPage = props => (
  <ProfileRouteAnonymous>
    <ProposalList headerType="anonymous" />
  </ProfileRouteAnonymous>
)

export default ProposalsAnonymousPage
